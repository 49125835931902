import React from "react";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {Button, Container, Grid, Hidden, IconButton, Paper, Snackbar, TextField, Typography} from "@material-ui/core";
import {ArrowBack, Save} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectUserState, IUserProfile, setUserProfile} from "../features/userSlice";
import {useForm, ValidationOptions} from "react-hook-form";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        paperMessages: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        backButton: {
            paddingTop: theme.spacing(1),
        },
        formWrapper: {
            width: '100%',
        },
        sendButtonWrapper: {
            position: 'relative',
        },
        snackbarAlertIcon: {
            alignItems: 'center'
        }
    }),
);

function ProfileComponent() {
    const classes = useStyles();
    const userState = useSelector(selectUserState);
    let history = useHistory();
    const dispatch = useDispatch();

    const [showSuccess, setShowSuccess] = React.useState(false);

    const goBack = () => {
        history.push('/');
    }

    const defaultValues: IUserProfile = {
        firstName: userState.firstName ?? "",
        lastName: userState.lastName ?? "",
        birthNumber: userState.birthNumber ?? "",
        email: userState.email ?? "",
        phoneNumber: userState.phoneNumber ?? "",
    }


    const onSubmit = async (data: IUserProfile) => {

        let userProfile: IUserProfile = {
            firstName: data.firstName,
            lastName: data.lastName,
            birthNumber: data.birthNumber,
            phoneNumber: data.phoneNumber,
            email: data.email,
        }

        dispatch(setUserProfile(userProfile));
        setShowSuccess(true);
    }

    const {handleSubmit, register, errors, triggerValidation } = useForm<IUserProfile>({
        defaultValues,
        mode: "onChange"
    });

    return (<Container fixed>
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={showSuccess}
            autoHideDuration={3000}
            onClose={() => setShowSuccess(false)}>
            <Alert onClose={() => setShowSuccess(false)} severity="success" classes={{
                icon: classes.snackbarAlertIcon
            }}>
                <Typography variant={"subtitle1"}>Údaje boli úspešne uložené.</Typography>
            </Alert>
        </Snackbar>
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3} className={classes.formWrapper}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={'h2'}>
                            <IconButton className={classes.backButton} aria-label="späť" onClick={goBack}>
                                <ArrowBack/>
                            </IconButton>
                            Profil a predvolené údaje
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            Tu môžete vyplniť svoje predvolené osobné údaje. Predvolené údaje slúžia na to, aby ste
                            nemuseli tieto informácie zadávať vždy pri používaní našich služieb (napríklad pri písaní
                            správy lekárovi), ale budú vám automaticky predvyplnené z tohoto profilu.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            Tieto predvolené údaje zostávajú uložené iba vo vašom prehliadači a nikam sa neposielajú.
                            Údaje môžete kedykoľvek vymazať a to tak, že uložíte prázdny formulár.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            id="firstName"
                            label="Krstné meno"
                            name={"firstName"}
                            placeholder={"Jožko"}
                            error={!!errors.firstName?.message}
                            helperText={errors.firstName?.message}
                            inputRef={register({
                                maxLength: {
                                    value: 50,
                                    message: "Krstné meno môže mať maximálne 50 znakov."
                                },
                                pattern: {
                                    value: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/,
                                    message: "Krstné meno môže obsahovať iba písmená"
                                },
                            } as ValidationOptions)}
                            onBlur={async () => {
                                await triggerValidation("firstName");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            id="lastName"
                            label="Priezvisko"
                            name={"lastName"}
                            placeholder={"Mrkvička"}
                            error={!!errors.lastName?.message}
                            helperText={errors.lastName?.message}
                            inputRef={register({
                                maxLength: {
                                    value: 50,
                                    message: "Priezvisko môže mať maximálne 50 znakov."
                                },
                                pattern: {
                                    value: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/,
                                    message: "Priezvisko môže obsahovať iba písmená"
                                },
                            } as ValidationOptions)}
                            onBlur={async () => {
                                await triggerValidation("lastName")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            id="birthNumber"
                            label="Rodné číslo"
                            name={"birthNumber"}
                            placeholder={"123456/7890"}
                            error={!!errors.birthNumber?.message}
                            helperText={errors.birthNumber?.message}
                            inputRef={register({
                                pattern: {
                                    value: /^[0-9]{6}[/]?([0-9]{4}|[0-9]{3})$/,
                                    message: "Nesprávny formát rodného čisla."
                                },
                            } as ValidationOptions)}
                            onBlur={async () => {
                                await triggerValidation("birthNumber")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            id="phoneNumber"
                            label="Telefónne číslo"
                            name={"phoneNumber"}
                            placeholder={"+421 999 888 777"}
                            error={!!errors.phoneNumber?.message}
                            helperText={errors.phoneNumber?.message}
                            inputRef={register({
                                pattern: {
                                    value: /(^((((\+|00\s?)421)\s?)|0)(?!900|97|98|890|85|80|82)[0-9]{3} ?[0-9]{3} ?[0-9]{3}$|^$)/,
                                    message: "Nesprávy formát telefónneho čísla"
                                },
                            } as ValidationOptions)}
                            onBlur={async () => {
                                await triggerValidation("phoneNumber")
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            id="email"
                            label="E-mailová adresa"
                            name={"email"}
                            placeholder={"jozko.mrkvicka@email.sk"}
                            error={!!errors.email?.message}
                            helperText={errors.email?.message}
                            inputRef={register({
                                maxLength: {
                                    value: 150,
                                    message: "E-mail môže mať maximálne 150 znakov."
                                },
                                pattern: {
                                    //https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type%3Demail)
                                    //This requirement is a willful violation of RFC 5322, which defines a syntax for
                                    // e-mail addresses that is simultaneously too strict (before the "@" character),
                                    // too vague (after the "@" character), and too lax (allowing comments, whitespace
                                    // characters, and quoted strings in manners unfamiliar to most users) to be of practical use here.
                                    // eslint-disable-next-line no-control-regex
                                    value: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/,
                                    message: "Nesprávny formát e-mailu"
                                },
                            } as ValidationOptions)}
                            onBlur={async () => {
                                await triggerValidation("email")
                            }}
                        />
                    </Grid>
                    <Hidden smDown={true}>
                        <Grid item lg={9} md={6}/>
                    </Hidden>
                    <Grid item xs={12} lg={3} md={6}>
                        <div className={classes.sendButtonWrapper}>
                            <Button id="sendMessageButton" type={"submit"}
                                    fullWidth={true}
                                    variant={'contained'}
                                    endIcon={<Save/>}
                                    color={'primary'} value={"sendMessage"}>Uložiť</Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </Container>)
        ;
}

export default ProfileComponent;