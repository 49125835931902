import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {useHistory, useParams} from "react-router-dom";
import {
    Button, Checkbox, CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, FormControl, FormControlLabel, FormHelperText, Grid,
    IconButton, InputAdornment, Link,
    Paper, Snackbar,
    TextField,
    Typography, useMediaQuery
} from "@material-ui/core";
import {ArrowBack, Send} from "@material-ui/icons";
import React, {useCallback} from "react";
import {GetDoctorProfile, GetMessageHistory, SavePatientMessage} from "../../app/apiclient";
import {
    DoctorMessagingProfile,
    PatientMessage
} from "../../app/dtos";
import {useSelector} from "react-redux";
import {selectUserState} from "../../features/userSlice";
import {format} from "date-fns";
import useInterval from "../../features/useInterval";
import {Alert, Autocomplete} from "@material-ui/lab";
import {Controller, useForm, ValidationOptions} from "react-hook-form";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        paperMessages: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        backButton: {
            paddingTop: theme.spacing(1),
        },
        newMessageButton: {
            margin: theme.spacing(0.5),
        },
        formWrapper: {
            width: '100%',
        },
        sendButtonWrapper: {
            position: 'relative',
        },
        sendButtonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        historyWrapper: {
            marginBottom: theme.spacing(2),
        },
        historyHeader: {
            padding: theme.spacing(1)
        },
        messageWrapper: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        },
        message: {
            margin: theme.spacing(1),
        },
        messageTime: {
            display: 'block',
            margin: theme.spacing(0.5),
        },
        divider: {
            marginLeft: -theme.spacing(2),
            marginRight: -theme.spacing(2),
        },
        messageText: {
            maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '24px',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        reply: {
            marginTop: theme.spacing(3),
            margin: theme.spacing(1),
            textAlign: 'right'
        },
        replyTime: {
            display: 'block',
            margin: theme.spacing(0.5),
        },
        replyText: {
            maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            /*border: '1px solid',
            borderColor: theme.palette.primary.contrastText,*/
            borderRadius: '24px',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        emptyReply: {
            marginTop: theme.spacing(3)
        },
        closeToast: {
            padding: theme.spacing(0.5),
        },
        detailWrapper: {
            display: 'none',
        },
        more: {
            textAlign: 'right'
        },
        formControl: {},
        acceptedError: {
            color: 'red',
        },
        empty: {},
        detailsDialog: {},
        detailsDialogTitle: {
            padding: theme.spacing(3)
        },
        detailsDialogContent: {
            padding: theme.spacing(3),
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
        detailsDialogActions: {
            padding: theme.spacing(3)
        },
        detailsDialogContentGrid: {padding: theme.spacing(1)},
        detailsDialogContentGridItem: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        addresseeDivider: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        snackbarAlertIcon: {
            alignItems: 'center'
        }
    }),
);

interface ISubject {
    text: string;
}

interface ISendMessageFormValues {
    subject: ISubject,
    firstName: string,
    lastName: string,
    birthNumber: string,
    email: string,
    phoneNumber: string,
    message: string,
    acceptTerms: boolean
}

const subjects = [
    {text: "Žiadosť o predpis liekov"},
    {text: "Žiadosť o telefonickú konzultáciu zdravotného stavu"},
    {text: "Zasielam svoje kontaktné údaje"},
]

const getDoctorDisplayName = (profile: DoctorMessagingProfile): string => {
    return `${profile.titleBefore ? `${profile.titleBefore} ` : ""}`
        + `${profile.firstName ? `${profile.firstName} ` : ""}`
        + `${profile.middleName ? `${profile.middleName} ` : ""}`
        + `${profile.lastName ? `${profile.lastName}` : ""}`
        + ` ${profile.titleAfter ? ` ${profile.titleAfter}` : ""}`;
}

const getDoctorDisplayTitle = (profile: DoctorMessagingProfile): string => {
    return `${profile.kodLekara ? `${profile.kodLekara} - ` : ""}`
        + getDoctorDisplayName(profile)
        + `${profile.nazovPzs ? `, ${profile.nazovPzs}` : ""}`
        + `${profile.specialization ? ` (${profile.specialization})` : ""}`;
}

function MessagesComponent() {
    const classes = useStyles();
    let {id} = useParams();
    let history = useHistory();
    const userState = useSelector(selectUserState);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultProps = {
        options: subjects,
        getOptionLabel: ((option: ISubject | string) => typeof option === 'string' ? option : option.text),
    };

    const [title, setTitle] = React.useState(`Lekár ${id}`);
    const [, setDoctorProfile] = React.useState<DoctorMessagingProfile | null>(null);
    const [messageHistory, setMessageHistory] = React.useState<PatientMessage[]>([]);

    const [activeMessage, setActiveMessage] = React.useState<PatientMessage | null>(null);
    const [detailDialogOpen, setDetailDialogOpen] = React.useState(false);

    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showFailure, setShowFailure] = React.useState(false);

    const [isSending, setIsSending] = React.useState(false);

    document.title = title;

    const defaultValues: ISendMessageFormValues = {
        subject: {text: ""},
        firstName: userState.firstName ?? "",
        lastName: userState.lastName ?? "",
        birthNumber: userState.birthNumber ?? "",
        email: userState.email ?? "",
        phoneNumber: userState.phoneNumber ?? "",
        message: "",
        acceptTerms: false,
    }

    console.log(defaultValues);

    const {handleSubmit, register, control, errors, triggerValidation, reset} = useForm<ISendMessageFormValues>({
        defaultValues,
        mode: "onChange"
    });

    const resetFormToDefault = () => {
        reset({
            ...defaultValues
        }, {
            errors: false,
            dirtyFields: false,
            dirty: false,
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
        })
    }

    const {executeRecaptcha} = useGoogleReCaptcha();
    const onSubmit = async (data: ISendMessageFormValues) => {

        // TODO poriešiť error
        if (!executeRecaptcha) {
            return;
        }

        setIsSending(true);
        const patientMessage = new PatientMessage();
        patientMessage.patientTempId = userState.userId!;
        patientMessage.doctorMessagingProfileId = id!;
        patientMessage.patientFirstName = data.firstName;
        patientMessage.patientLastName = data.lastName;
        patientMessage.patientBirthNumber = data.birthNumber;
        patientMessage.patientPhoneNumber = data.phoneNumber;
        patientMessage.patientEmail = data.email;
        patientMessage.subject = data.subject.text;
        patientMessage.message = data.message;
        try {

            const recaptchaToken = await executeRecaptcha("sendMessage");
            const response = await SavePatientMessage(userState.userId!, userState.verificationToken!, recaptchaToken, patientMessage);
            if (response) {
                setShowSuccess(true);
                resetFormToDefault();
                await getMessageHistory();
            } else {
                setShowFailure(true);
            }
        } catch (e) {
            console.log(e);
            setShowFailure(true);
        }
        setIsSending(false);
    }

    const goBack = () => {
        history.push('/');
    }


    const getDoctorInfo = useCallback(
        async () => {

            if (!id) {
                return;
            }

            const doctorProfile = await GetDoctorProfile(id);

            if (!doctorProfile) {
                history.push('/');
                return;
            }

            setDoctorProfile(doctorProfile);
            setTitle(getDoctorDisplayTitle(doctorProfile));
        }, [history, id]
    )

    React.useEffect(() => {
        (async () => {
            await getDoctorInfo()
        })();
    }, [getDoctorInfo]);

    const getMessageHistory = useCallback(
        async () => {

            if (!id) {
                return;
            }

            const messages = await GetMessageHistory(userState.userId!, userState.verificationToken!, id);

            if (!messages) {
                return;
            }

            setMessageHistory(messages);
        }, [id, userState.userId, userState.verificationToken]
    )

    React.useEffect(
        () => {
            (async () => {
                await getMessageHistory();
            })();
        },
        [getMessageHistory]
    );

    useInterval(() => {
        (async () => {
            await getMessageHistory()
        })();
    }, 30000);

    const className = errors.acceptTerms?.message ? classes.acceptedError : classes.empty;

    return (
        <Container fixed>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={showSuccess}
                autoHideDuration={10000}
                onClose={() => setShowSuccess(false)}>
                <Alert onClose={() => setShowSuccess(false)} severity="success" classes={{
                    icon: classes.snackbarAlertIcon
                }}>
                    <Typography variant={"subtitle1"}>Správa pre lekára bola úspešne odoslaná! Prípadnú odpoveď od lekára si
                        môžete prečítať iba v tomto prehliadači na tomto zariadení v histórii správ pod týmto formulárom.</Typography>
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={showFailure}
                autoHideDuration={10000}
                onClose={() => setShowFailure(false)}>
                <Alert onClose={() => setShowFailure(false)} severity="error" classes={{
                    icon: classes.snackbarAlertIcon
                }}>
                    <Typography variant={"subtitle1"}>Pri odosielaní správy nastala chyba. Skúste to prosím znovu
                        neskôr.</Typography>
                </Alert>
            </Snackbar>
            <Paper className={classes.paper}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid className={classes.formWrapper} container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant={"h6"} component={'h2'}>
                                <IconButton className={classes.backButton} aria-label="späť" onClick={goBack}>
                                    <ArrowBack/>
                                </IconButton>
                                Napíšte správu lekárovi
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle1'}>Adresát: <b>{title}</b></Typography>
                            <Divider className={classes.addresseeDivider}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                {...defaultProps}
                                freeSolo={
                                    true
                                }
                                id="subject"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        id="subject.text"
                                        name={"subject.text"}
                                        placeholder={"(bez predmetu)"}
                                        error={!!errors.subject?.text?.message}
                                        helperText={errors.subject?.text?.message}
                                        inputRef={register({
                                            maxLength: {
                                                value: 250,
                                                message: "Predmet správy môže mať maximálne 250 znakov"
                                            },
                                        } as ValidationOptions)}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: <InputAdornment position="start">Predmet:</InputAdornment>,
                                        }}

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'subtitle1'}>Vaše údaje:</Typography>
                            <Typography variant={'caption'}>Položky označené * sú povinné</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth={true}
                                id="firstName"
                                label="Krstné meno *"
                                name={"firstName"}
                                placeholder={"Jožko"}
                                error={!!errors.firstName?.message}
                                helperText={errors.firstName?.message}
                                inputRef={register({
                                    required: "Vyplňte prosím Vaše krstné meno",
                                    maxLength: {
                                        value: 50,
                                        message: "Krstné meno môže mať maximálne 50 znakov."
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/,
                                        message: "Krstné meno môže obsahovať iba písmená"
                                    },
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("firstName")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth={true}
                                id="lastName"
                                label="Priezvisko *"
                                name={"lastName"}
                                placeholder={"Mrkvička"}
                                error={!!errors.lastName?.message}
                                helperText={errors.lastName?.message}
                                inputRef={register({
                                    required: "Vyplňte prosím Vaše priezvisko",
                                    maxLength: {
                                        value: 50,
                                        message: "Priezvisko môže mať maximálne 50 znakov."
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/,
                                        message: "Priezvisko môže obsahovať iba písmená"
                                    },
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("lastName")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth={true}
                                id="birthNumber"
                                label="Rodné číslo *"
                                name={"birthNumber"}
                                placeholder={"123456/7890"}
                                error={!!errors.birthNumber?.message}
                                helperText={errors.birthNumber?.message}
                                inputRef={register({
                                    required: "Vyplňte prosím rodné číslo",
                                    pattern: {
                                        value: /^[0-9]{6}[/]?([0-9]{4}|[0-9]{3})$/,
                                        message: "Nesprávny formát rodného čisla."
                                    },
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("birthNumber")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                id="phoneNumber"
                                label="Telefónne číslo *"
                                name={"phoneNumber"}
                                placeholder={"+421 999 888 777"}
                                error={!!errors.phoneNumber?.message}
                                helperText={errors.phoneNumber?.message}
                                inputRef={register({
                                    required: "Vyplňte prosím telefónne číslo",
                                    pattern: {
                                        value: /(^((((\+|00\s?)421)\s?)|0)(?!900|97|98|890|85|80|82)[0-9]{3} ?[0-9]{3} ?[0-9]{3}$|^$)/,
                                        message: "Nesprávy formát telefónneho čísla"
                                    },
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("phoneNumber")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                id="email"
                                label="E-mailová adresa"
                                name={"email"}
                                placeholder={"jozko.mrkvicka@e-mail.sk"}
                                error={!!errors.email?.message}
                                helperText={errors.email?.message}
                                inputRef={register({
                                    maxLength: {
                                        value: 150,
                                        message: "E-mail môže mať maximálne 150 znakov."
                                    },
                                    pattern: {
                                        //https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type%3Demail)
                                        //This requirement is a willful violation of RFC 5322, which defines a syntax for
                                        // e-mail addresses that is simultaneously too strict (before the "@" character),
                                        // too vague (after the "@" character), and too lax (allowing comments, whitespace
                                        // characters, and quoted strings in manners unfamiliar to most users) to be of practical use here.
                                        // eslint-disable-next-line no-control-regex
                                        value: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/,
                                        message: "Nesprávny formát e-mailu"
                                    },
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("email")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                id={"message"}
                                label="Vaša správa (maximálne 2000 znakov) *"
                                multiline={true}
                                name={"message"}
                                placeholder={"Dobrý deň..."}
                                rows={4}
                                error={!!errors.message?.message}
                                helperText={errors.message?.message}
                                inputRef={register({
                                    required: "Vyplňte prosím správu pre lekára",
                                    maxLength: {
                                        value: 2000,
                                        message: "Správa pre lekára môže mať maximálne 2000 znakov"
                                    }
                                } as ValidationOptions)}
                                onBlur={async () => {
                                    await triggerValidation("message")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <FormControl error={!!errors.acceptTerms?.message}
                                         component="fieldset"
                                         className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            as={<Checkbox
                                                className={className}
                                                color={'primary'}
                                                id={"acceptTermsChck"}
                                                name={"acceptTermsChck"}
                                                value="true"
                                            />}
                                            id={"acceptTerms"}
                                            name={"acceptTerms"}
                                            control={control}
                                            rules={{
                                                required: "Musíte súhlasiť s podmienkami.",
                                            }}
                                            defaultValue={false}
                                        />
                                    }
                                    label={<Typography>
                                        Súhlasím s podmienkami používania služby a s podmienkami ochrany a spracovania
                                        osobných údajov *
                                    </Typography>}
                                />
                                {
                                    errors.acceptTerms?.message &&
                                    <FormHelperText>{errors.acceptTerms?.message}</FormHelperText>
                                }
                            </FormControl>
                            <Typography><Link target={"_blank"}
                                              href={'https://www.softprogres.sk/informacie/elekari-pacient'}>Podmienky používania služby pre pacientov.</Link></Typography>
                            <Typography><Link target={"_blank"}
                                              href={'https://www.softprogres.sk/informacie/elekari-sk-gdpr'}>Podmienky ochrany a spracovania osobných údajov.</Link></Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div className={classes.sendButtonWrapper}>
                                <Button id="sendMessageButton" type={"submit"}
                                        fullWidth={true}
                                        variant={'contained'}
                                        endIcon={<Send/>}
                                        disabled={isSending}
                                        color={'primary'} value={"sendMessage"}>Odoslať</Button>
                                {isSending && <CircularProgress size={24} className={classes.sendButtonProgress}/>}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Paper className={classes.paperMessages}>
                <div className={classes.historyWrapper}>
                    <Typography variant={"h6"} className={classes.historyHeader}>
                        História správ za posledné 2 týždne
                    </Typography>
                </div>
                {
                    messageHistory.length > 0 ?
                        (messageHistory.map((msg) => {
                            const messageTime = format(new Date(parseInt(msg.messageTime.substr(6))), "d.M. 'o' H:mm");
                            const replyTime = msg.replyTime ? format(new Date(parseInt(msg.replyTime.substr(6))), "d.M. 'o' H:mm") : null;
                            return <React.Fragment key={msg.id}>
                                <Divider className={classes.divider}/>
                                <div className={classes.messageWrapper}>
                                    <div className={classes.message}>
                                        <Typography className={classes.messageTime} variant={'body2'}>
                                            Napísali ste {messageTime}
                                        </Typography>
                                        <Typography className={classes.messageText} variant={'body1'}>
                                            {msg.message}
                                        </Typography>
                                    </div>
                                    <div className={classes.reply}>
                                        {msg.reply
                                            ?
                                            <>

                                                <Typography className={classes.replyText} variant={'body1'}>
                                                    {msg.reply}
                                                </Typography>
                                                <Typography className={classes.replyTime} variant={'body2'}>Lekár
                                                    odpovedal {replyTime}</Typography>
                                            </>
                                            :
                                            <Typography className={classes.emptyReply} variant={'body2'}>
                                                Žiadna odpoveď od lekára.
                                            </Typography>
                                        }
                                    </div>
                                    <div className={classes.detailWrapper}>

                                    </div>
                                    <div className={classes.more}>
                                        <Button onClick={() => {
                                            setActiveMessage(msg);
                                            setDetailDialogOpen(true);
                                        }} color="primary">Zobraziť detaily</Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        }))
                        :
                        <div className={classes.historyWrapper}>
                            <Typography variant={"subtitle1"}>
                                V histórii sa neevidujú žiadne správy odoslané z toho webového prehliadača za posledné 2
                                týždne.
                            </Typography>
                        </div>
                }
            </Paper>
            {activeMessage &&
            <Dialog
                className={classes.detailsDialog}
                open={detailDialogOpen}
                fullScreen={fullScreen}
                scroll={'paper'}
                onClose={() => {
                    setDetailDialogOpen(false);
                    setActiveMessage(null);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    className={classes.detailsDialogTitle}
                    id="alert-dialog-title">Detaily správy</DialogTitle>
                <DialogContent
                    dividers={true}
                    className={classes.detailsDialogContent}>
                    <Grid className={classes.detailsDialogContentGrid} container={true} spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography><b>Meno a priezvisko</b><br/>
                                {`${activeMessage.patientFirstName ||
                                <i>Nevyplnené</i>} ${activeMessage.patientLastName}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography><b>Rodné číslo:</b><br/>
                                {activeMessage.patientBirthNumber || <i>Nevyplnené</i>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography><b>E-mail</b><br/>
                                {activeMessage.patientEmail || <i>Nevyplnené</i>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography><b>Telefónne číslo:</b><br/>
                                {activeMessage.patientPhoneNumber || <i>Nevyplnené</i>}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography><b>Vaša správa
                                (odoslané {format(new Date(parseInt(activeMessage.messageTime.substr(6))), "d.M. 'o' H:mm")}):</b><br/>
                                <u>Predmet: {activeMessage.subject}</u><br/>
                                {activeMessage.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {activeMessage.reply
                                ?
                                <Typography>
                                    <b>Odpoveď lekára
                                        (odoslané {format(new Date(parseInt(activeMessage.replyTime.substr(6))), "d.M. 'o' H:mm")}):</b><br/>
                                    {activeMessage.reply}
                                </Typography>
                                :
                                <Typography>
                                    <b>Odpoveď lekára:</b><br/><i>Lekár na správu zatiaľ neodpovedal.</i>
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    className={classes.detailsDialogActions}>
                    <Button variant={"contained"} onClick={() => {
                        setDetailDialogOpen(false);
                        setActiveMessage(null);
                    }}>
                        Zatvoriť
                    </Button>
                </DialogActions>
            </Dialog>
            }
        </Container>
    );
}


export default MessagesComponent;
