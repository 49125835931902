import React from "react";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Avatar, Hidden, IconButton, Tooltip} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import WebLogo from './assets/img/sp_logo/web_logo.png';
import {Person} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            marginLeft:theme.spacing(2)
        },
    }),
);

function Header() {
    const classes = useStyles();
    const history = useHistory();


    /*const goToLogin = () => {
        history.push('/prihlasenie');
    }*/

    const goToProfile = () => {
        history.push('/profil');
    }

    const goToDashboard = () => {
        history.push('/');
    }

    return (
        <AppBar position="static">
            <Toolbar>
                <Avatar alt="SOFTPROGRES s.r.o." src={WebLogo} />
                <Typography variant="h6" component={'h1'} className={classes.title} onClick={goToDashboard}>
                    eLekári.sk<Hidden smDown={true}> - Online portál pre lekárov a pacientov</Hidden>
                </Typography>
                <IconButton onClick={goToProfile} color="inherit"><Tooltip title={"Profil"}><Person/></Tooltip></IconButton>
                {/*process.env.REACT_APP_HOST_ENV === "development" && <>
                    <IconButton color="inherit"><Tooltip title={"Nastavenia"}><SettingsIcon/></Tooltip></IconButton>
                    <IconButton color="inherit"><Tooltip title={"Tmavá téma"}><Brightness4Icon/></Tooltip></IconButton>
                    <IconButton onClick={goToProfile} color="inherit"><Tooltip title={"Profil"}><PersonIcon/></Tooltip></IconButton>
                    <Button onClick={goToLogin} color="inherit">Prihlásiť</Button>
                </>*/}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
