import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import {routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from 'history';
import reduxThunk from 'redux-thunk';
import {createLogger} from "redux-logger";
import storage from 'redux-persist/es/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from "./reducers";

export const history = createBrowserHistory();

const reduxLogger = createLogger({
  // https://github.com/LogRocket/redux-logger
  // ...options
});

const persistConfig = {
  storage,
  key: 'root',
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));


let middleware: Middleware[] = [routerMiddleware(history), reduxThunk];

if(process.env.REACT_APP_HOST_ENV !== 'production') {
  middleware = [...middleware, reduxLogger];
}

export const store = configureStore({
  middleware,
  devTools: true,
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
