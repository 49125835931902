import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Header from "./Header";
import {Route, Switch} from 'react-router-dom';
import MessagesComponent from "./components/messages/MessagesComponent";
import {useDispatch, useSelector} from "react-redux";
import {createTempUserAsync, selectUserState} from "./features/userSlice";
import SearchDoctorComponent from "./components/search/SearchDoctorComponent";
import ProfileComponent from "./components/ProfileComponent";
import {PatientTempProfileExists} from "./app/apiclient";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

function App() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const userState = useSelector(selectUserState);

    if (!userState.userId || !userState.verificationToken) {
        dispatch(createTempUserAsync());
    }
    else
    {
        PatientTempProfileExists(userState.userId).then(result => {
            if(!result)
            {
                dispatch(createTempUserAsync());
            }
        })
    }

    return (
        <div className={classes.root}>
            <Header/>
            <Switch>
                {/*(
                    <>
                        <Route exact={true} path={"/hladat/lekar"}>
                            <SearchDoctorComponent/>
                        </Route>
                        <Route exact={true} path={"/hladat/cakaren"}>
                            <SearchWaitingRoomsComponent/>
                        </Route>
                        <Route exact={true} path={"/lekar/:id/spravy"}>
                            <MessagesComponent/>
                        </Route>
                        <Route exact={true} path={"/cakaren/:id"}>
                            <WaitingRoomComponent/>
                        </Route>
                        <Route exact={true} path={"/profil"}>
                            <ProfileComponent/>
                        </Route>
                        <Route exact={true} path={"/"}>
                            <DashboardComponent/>
                        </Route>
                    </>
                )*/}
                <>
                    <Route exact={true} path={"/lekar/:id/spravy"}>
                        <MessagesComponent/>
                    </Route>
                    <Route exact={true} path={"/profil"}>
                        <ProfileComponent/>
                    </Route>
                    <Route exact={true} path={"/"}>
                        <SearchDoctorComponent/>
                    </Route>
                </>
            </Switch>
        </div>
    );
}

export default App;
