import * as rm from "typed-rest-client/RestClient";
import {
    Appointment,
    AppointmentResponse,
    AppointmentsResponse,
    Authenticate,
    AuthenticateResponse,
    CreatePatientMessage,
    CreatePatientMessageResponse,
    CreatePatientTempCredentials,
    CreatePatientTempCredentialsResponse,
    DoctorMessagingProfile,
    GetDoctorMessagingProfile,
    GetDoctorMessagingProfileResponse,
    GetDoctorMessagingProfiles, GetDoctorMessagingProfilesResponse,
    GetPatientSentMessages,
    PatientMessage,
    PatientMessagesResponse, PatientTempCredentials,
    UpdateAppointment,
    WaitingRoom,
    WaitingRoomsResponse,
    CheckPatientTempProfileExists,
    CheckPatientTempProfileExistsResponse
} from "./dtos";
import {JsonServiceClient} from "@servicestack/client";

const restClient: rm.RestClient = new rm.RestClient(null, process.env.REACT_APP_API_URL);
export const client = new JsonServiceClient(process.env.REACT_APP_API_URL);

export const AuthenticateWebUser = async () : Promise<boolean> => {

    const request = new Authenticate();
    try {
        const response: AuthenticateResponse = await client.post<AuthenticateResponse>(request);
        if(response.sessionId)
        {
            return true;
        }
    } catch(e) {
        console.log(e);
    }

    request.provider = "credentials";
    request.userName = "web";
    request.password = "g50Y^Ct2*KQq5hHJqPljZW7Ke)!9awMJ*Ym3AFpHmt7$wK8Hxb%avBio7s&WOap9";

    const response : AuthenticateResponse = await client.post<AuthenticateResponse>(request);

    if(response.sessionId)
    {
        client.setBearerToken(response.bearerToken);
        return true;
    }

    return false;
}

export const PatientTempProfileExists = async(patientTmpProfileId: string) : Promise<boolean> => {

    await AuthenticateWebUser();

    const request = new CheckPatientTempProfileExists();
    request.tmpPatientProfileId = patientTmpProfileId;
    try {
        const response = await client.get<CheckPatientTempProfileExistsResponse>(request);
        return Promise.resolve(response.tmpPatientProfileId === patientTmpProfileId);
    }
    catch (e) {
        console.log(e);
        return Promise.resolve(false);
    }
}

export const CreateTempProfile = async() : Promise<CreatePatientTempCredentialsResponse> => {

    await AuthenticateWebUser();

    const request = new CreatePatientTempCredentials();
    request.patientTempCredentials = new PatientTempCredentials();
    return await client.post(request);
}

export const SetAuthHeaders = (userId: string, verificationToken: string) : void => {
    client.headers.append("TempProfileId",userId);
    client.headers.append("VerificationToken",verificationToken);
}

export const UnsetAuthHeaders = () : void => {
    client.headers.delete("TempProfileId");
    client.headers.delete("VerificationToken");
}

export const GetDoctorProfiles = async(query: string) : Promise<DoctorMessagingProfile[]> => {

    await AuthenticateWebUser();

    const request = new GetDoctorMessagingProfiles();
    request.query = query;
    let returnedDoctorMessagingProfiles: DoctorMessagingProfile[];
    try {
        const response: GetDoctorMessagingProfilesResponse = await client.get<GetDoctorMessagingProfilesResponse>(request);
        returnedDoctorMessagingProfiles = response.doctorMessagingProfiles;
    }
    catch (e) {
        console.log(e);
        returnedDoctorMessagingProfiles = [];
    }

    return Promise.resolve(returnedDoctorMessagingProfiles);
}

export const GetDoctorProfile = async(profileId: string) : Promise<DoctorMessagingProfile | null> => {

    await AuthenticateWebUser();

    const request = new GetDoctorMessagingProfile();
    request.doctorMessagingProfileId = profileId;
    let returnedDoctorMessagingProfile :DoctorMessagingProfile | null;
    try {
        const response : GetDoctorMessagingProfileResponse = await client.get<GetDoctorMessagingProfileResponse>(request);
        returnedDoctorMessagingProfile = response.doctorMessagingProfile;
    }
    catch (e) {
        console.log(e);
        returnedDoctorMessagingProfile = null;
    }

    return Promise.resolve(returnedDoctorMessagingProfile);
}

export const GetMessageHistory = async(userId: string, verificationToken: string, doctorId: string ) : Promise<PatientMessage[]> => {
    const request = new GetPatientSentMessages();
    request.doctorProfileId = doctorId;

    SetAuthHeaders(userId, verificationToken);
    let returnedPatientMessages : PatientMessage[];
    try {
        const response = await client.get<PatientMessagesResponse>(request);
        returnedPatientMessages = response.patientMessages;
    }
    catch (e) {
        console.log(e);
        returnedPatientMessages = [];
    }
    UnsetAuthHeaders();
    return Promise.resolve(returnedPatientMessages);
}

export const SavePatientMessage = async(userId: string, verificationToken: string, recaptchaToken: string, patientMessage: PatientMessage) :Promise<PatientMessage | null> => {
    const request = new CreatePatientMessage();
    request.patientMessage = patientMessage;
    client.headers.append("RecaptchaToken",recaptchaToken);
    SetAuthHeaders(userId, verificationToken);
    let returnedPatientMessage: PatientMessage | null;
    try {
        const response = await client.post<CreatePatientMessageResponse>(request);
        returnedPatientMessage = response.patientMessage;
    }
    catch(e)
    {
        console.log(e);
        returnedPatientMessage = null;
    }
    UnsetAuthHeaders();
    client.headers.delete("RecaptchaToken");
    return Promise.resolve(returnedPatientMessage);
}

export const BookAppointment = async (appointmentId: number, date: string) : Promise<Appointment> => {

    const request = new UpdateAppointment();
    request.appointmentId = appointmentId;
    request.bookingTime = date;

    const response: AppointmentResponse = await client.patch<AppointmentResponse>(request);

    return Promise.resolve(response.appointment /* TODO ? response.appointment : null*/);
}

export const GetAppointments = async(appointmentId: number) : Promise<Appointment[]> => {
    const response: rm.IRestResponse<AppointmentsResponse> = await restClient.get<AppointmentsResponse>(`/reception/appointments/waitingroom/${appointmentId}.json`);

    return Promise.resolve(response.result?.appointments ? response.result.appointments : []);
}

export const GetWaitingRoom = async(waitingRoomId: number) : Promise<WaitingRoom | null> => {
    const response: rm.IRestResponse<WaitingRoomsResponse> = await restClient.get<WaitingRoomsResponse>(`/reception/waitingrooms/${waitingRoomId}.json`);

    return Promise.resolve(response.result?.waitingRooms && response.result?.waitingRooms.length > 0 ? response.result.waitingRooms[0] : null)
}

export const GetWaitingRooms = async() : Promise<WaitingRoom[]> => {
    const response: rm.IRestResponse<WaitingRoomsResponse> = await restClient.get<WaitingRoomsResponse>(`/reception/waitingrooms.json`);

    return Promise.resolve(response.result?.waitingRooms ? response.result.waitingRooms : [])
}
