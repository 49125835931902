import {CircularProgress} from "@material-ui/core";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        loadingWrapper: {
            margin: 'auto',
            width: '50%',
            border: '3px solid green',
            padding: '10px',
        },
    }),
);

function Loading()
{
    const classes = useStyles();

    return(<div className={classes.loadingWrapper}>
        <CircularProgress />
    </div>);
}

export default Loading;