import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../app/store';
import {CreatePatientTempCredentialsResponse} from "../app/dtos";
import {CreateTempProfile} from "../app/apiclient";

export interface UserState {
    userId: string | null;
    verificationToken: string | null;
    firstName: string,
    lastName: string,
    birthNumber: string,
    phoneNumber: string,
    email: string
}

export interface IUserProfile {
    firstName: string,
    lastName: string,
    birthNumber: string,
    phoneNumber: string,
    email: string
}

const DefaultUserProfile = {
    birthNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
};

const initialState: UserState = {
    userId: null,
    verificationToken: null,
    ...DefaultUserProfile
};

export const slice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setTempUser: (state, action: PayloadAction<CreatePatientTempCredentialsResponse>) => {
            state.userId = action.payload.patientTempCredentials.id;
            state.verificationToken = action.payload.patientTempCredentials.verificationToken;
        },
        setUserProfile: (state, action: PayloadAction<IUserProfile>) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.birthNumber = action.payload.birthNumber;
            state.phoneNumber = action.payload.phoneNumber;
            state.email = action.payload.email;
        }
    },
});

export const {setTempUser, setUserProfile} = slice.actions;

export const createTempUserAsync = (): AppThunk => async dispatch => {
        const tempProfileResponse = await CreateTempProfile();
        dispatch(setTempUser(tempProfileResponse));
};

export const selectUserState = (state: RootState) =>
    state.user;

export const userReducer = slice.reducer;
