/* Options:
Date: 2020-05-04 23:00:15
Version: 5.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://api.dev.softprogres.sk

//GlobalNamespace:
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class DoctorProfile
{
    /**
     * Unikátny identifikátor.
     */
        // @Required()
    public id: string;

    /**
     * Identifikačné číslo oragnizácie poskytovateľa zdravotnej starostlivosti.
     */
        // @Required()
        // @StringLength(15)
    public icoPzs: string;

    /**
     * Názov poskytovateľa zdravotnej starostlivosti.
     */
        // @Required()
        // @StringLength(300)
    public nazovPzs: string;

    /**
     * Kód poskytovateľa zdravotnej starostlivosti (6-miestny v tvare NXXXXX).
     */
        // @Required()
        // @StringLength(15)
    public kodPzs: string;

    /**
     * Kód ambulancie/oddelenia/pracoviska poskytovateľa zdravotnej starostlivosti (12-miestny v tvare NXXXXXYYYYYY).
     */
        // @Required()
        // @StringLength(15)
    public kodNz: string;

    /**
     * Kód lekára (9-miestny v tvare AXXXXXYYY).
     */
        // @Required()
        // @StringLength(15)
    public kodLekara: string;

    /**
     * Špecializácia lekára (pediater, kardiológ,...).
     */
        // @Required()
        // @StringLength(50)
    public specialization: string;

    /**
     * Titul pred menom lekára..
     */
        // @StringLength(50)
    public titleBefore: string;

    /**
     * Krstné meno lekára.
     */
        // @StringLength(50)
    public firstName: string;

    /**
     * Stredne meno (mená) lekára.
     */
        // @StringLength(50)
    public middleName: string;

    /**
     * Priezvisko lekára.
     */
        // @StringLength(50)
    public lastName: string;

    /**
     * Titul za menom lekára.
     */
        // @StringLength(50)
    public titleAfter: string;

    /**
     * Ulica a popisné číslo prevádzky poskytovateľa zdravotnej starostlivosti.
     */
        // @StringLength(120)
    public streetAndNumber: string;

    /**
     * Mesto prevádzky poskytovateľa zdravotnej starostlivosti.
     */
        // @StringLength(80)
    public city: string;

    /**
     * Poštové smerovacie číslo prevádzky poskytovateľa zdravotnej starostlivosti.
     */
        // @StringLength(15)
    public postalCode: string;

    /**
     * 1. Telefónne číslo prevádzky poskytovateľa zdravotnej starostlivosti. Validné sú iba mobilné čísla SR. Zadáva sa bez národnej predvoľby. Musí spĺňať validitu regulárneho výrazu "^(09|4219)[0-9]{8}$".
     */
        // @StringLength(40)
    public phoneNumber: string;

    /**
     * 2. Telefónne číslo prevádzky poskytovateľa zdravotnej starostlivosti. Validné sú iba mobilné čísla SR. Zadáva sa bez národnej predvoľby. Musí spĺňať validitu regulárneho výrazu "^(09|4219)[0-9]{8}$".
     */
        // @StringLength(40)
    public mobilePhoneNumber: string;

    /**
     * 1. E-mailová adresa prevádzky poskytovateľa zdravotnej starostlivosti. Musí spĺňať validitu regulárneho výrazu "^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$".
     */
        // @StringLength(120)
    public primaryEmail: string;

    /**
     * 2. E-mailová adresa prevádzky poskytovateľa zdravotnej starostlivosti. Musí spĺňať validitu regulárneho výrazu "^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$".
     */
        // @StringLength(120)
    public alternateEmail: string;

    /**
     * Príznak, či lekár používa správy.
     */
        // @Required()
    public isUsingMessages: boolean;

    /**
     * Príznak, či lekár používa časenky.
     */
        // @Required()
    public isUsingAppointments: boolean;

    public constructor(init?: Partial<DoctorProfile>) { (Object as any).assign(this, init); }
}

export class DoctorCredentials
{
    public userName: string;
    public password: string;

    public constructor(init?: Partial<DoctorCredentials>) { (Object as any).assign(this, init); }
}

export class ActiveSubstance
{
    public id: number;
    // @StringLength(255)
    public title: string;

    // @StringLength(255)
    public description: string;

    public constructor(init?: Partial<ActiveSubstance>) { (Object as any).assign(this, init); }
}

export class AtcGroup
{
    public code: string;
    public name: string;
    public description: string;

    public constructor(init?: Partial<AtcGroup>) { (Object as any).assign(this, init); }
}

export class Country
{
    // @StringLength(12)
    public code: string;

    // @StringLength(100)
    public title: string;

    // @StringLength(100)
    public description: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class DrugResult
{
    public code: string;
    public title: string;
    public price: number;
    public supplement: string;
    public surcharge: number;
    public atcGroupCode: string;
    public atcGroupName: string;
    public manufacturerCode: string;
    public manufacturerName: string;

    public constructor(init?: Partial<DrugResult>) { (Object as any).assign(this, init); }
}

export class DrugSuggestion
{
    // @StringLength(500)
    public suggestionValue: string;

    public suggestionRating: number;

    public constructor(init?: Partial<DrugSuggestion>) { (Object as any).assign(this, init); }
}

export class IndicationGroup
{
    public id: number;
    // @StringLength(100)
    public title: string;

    // @StringLength(100)
    public description: string;

    public constructor(init?: Partial<IndicationGroup>) { (Object as any).assign(this, init); }
}

export class PrescriptionRestriction
{
    // @StringLength(12)
    public code: string;

    // @StringLength(100)
    public title: string;

    // @StringLength(100)
    public description: string;

    public constructor(init?: Partial<PrescriptionRestriction>) { (Object as any).assign(this, init); }
}

export enum FtpProtocol
{
    Ftp = 'Ftp',
    Ftps = 'Ftps',
    Ftpes = 'Ftpes',
    Sftp = 'Sftp',
}

export enum ExamContainer
{
    None = 'None',
    Zip = 'Zip',
    ProdataZip = 'ProdataZip',
}

export enum ExamDeleteWhen
{
    Immediately = 0,
    OneDayAfter = 1,
    SevenDaysAfter = 7,
    MonthAfter = 30,
    Never = -1,
}

export class SvlzLab
{
    public id: number;
    public name: string;
    public isDoveraSvlzActive: boolean;
    // @StringLength(10)
    public groupIco: string;

    // @StringLength(10)
    public ico: string;

    // @StringLength(50)
    public spLogin: string;

    // @StringLength(50)
    public spPassword: string;

    public constructor(init?: Partial<SvlzLab>) { (Object as any).assign(this, init); }
}

export class DoctorMessagingProfile
{
    public id: string;
    public nazovPzs: string;
    public kodLekara: string;
    public specialization: string;
    public titleBefore: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public titleAfter: string;

    public constructor(init?: Partial<DoctorMessagingProfile>) { (Object as any).assign(this, init); }
}

export class PatientTempCredentials
{
    /**
     * Unikátny identifikátor.
     */
        // @Required()
    public id: string;

    /**
     * Unikárny token pre overenie pacienta.
     */
        // @Required()
        // @StringLength(300)
    public verificationToken: string;

    public constructor(init?: Partial<PatientTempCredentials>) { (Object as any).assign(this, init); }
}

export class PatientMessage
{
    /**
     * Id správy.
     */
    public id: number;
    /**
     * Id lekára, pre ktorého je správa určená.
     */
        // @Required()
    public doctorMessagingProfileId: string;

    /**
     * Id odosielatela správy.
     */
        // @Required()
    public patientTempId: string;

    /**
     * Príznak, či sa má správa pri načítaní správ ignorovať.
     */
    public isIgnored: boolean;
    /**
     * Rodné číslo odosielatela správy.
     */
        // @Required()
        // @StringLength(20)
    public patientBirthNumber: string;

    /**
     * Krstné meno odosielatela správy.
     */
        // @Required()
        // @StringLength(50)
    public patientFirstName: string;

    /**
     * Priezvisko odosielatela správy.
     */
        // @Required()
        // @StringLength(100)
    public patientLastName: string;

    /**
     * Telefón odosielatela správy.
     */
        // @Required()
        // @StringLength(40)
    public patientPhoneNumber: string;

    /**
     * E-mail odosielatela správy.
     */
        // @StringLength(120)
    public patientEmail: string;

    /**
     * Predmet správy pre lekára.
     */
        // @Required()
        // @StringLength(250)
    public subject: string;

    /**
     * Správa pre lekára.
     */
        // @Required()
        // @StringLength(2000)
    public message: string;

    /**
     * Čas odoslania správy.
     */
        // @Required()
    public messageTime: string;

    /**
     * Príznak, či si lekár správu prečítal.
     */
        // @Required()
    public isMessageRead: boolean;

    /**
     * Odpoveď pre pacienta.
     */
        // @StringLength(2000)
    public reply: string;

    /**
     * Čas odoslania odpovede.
     */
    public replyTime: string;

    public constructor(init?: Partial<PatientMessage>) { (Object as any).assign(this, init); }
}

export class WaitingRoom
{
    public id: number;
    public title: string;
    public description: string;
    public bookingInterval: number;
    public isOnline: boolean;

    public constructor(init?: Partial<WaitingRoom>) { (Object as any).assign(this, init); }
}

export class Appointment
{
    public id: number;
    public waitingRoomId: number;
    public hour: string;
    public firstName: string;
    public lastName: string;
    public date: string;
    public bookingTime: string;
    public number: string;
    public examinationTime: string;

    public constructor(init?: Partial<Appointment>) { (Object as any).assign(this, init); }
}

export class Comment
{
    public id: number;
    // @StringLength(50)
    public author: string;

    // @StringLength(100)
    public text: string;

    public created: string;

    public constructor(init?: Partial<Comment>) { (Object as any).assign(this, init); }
}

export class New
{
    public id: number;
    // @StringLength(255)
    public ttitle: string;

    public text: string;
    public dateFrom: string;
    public dateTo: string;

    public constructor(init?: Partial<New>) { (Object as any).assign(this, init); }
}

export class GetDoctorProfileResponse
{
    public responseStatus: ResponseStatus;
    public doctorProfile: DoctorProfile;

    public constructor(init?: Partial<GetDoctorProfileResponse>) { (Object as any).assign(this, init); }
}

export class CreateDoctorProfileResponse
{
    public responseStatus: ResponseStatus;
    public doctorCredentials: DoctorCredentials;
    public doctorProfile: DoctorProfile;

    public constructor(init?: Partial<CreateDoctorProfileResponse>) { (Object as any).assign(this, init); }
}

export class UpdateDoctorProfileResponse
{
    public responseStatus: ResponseStatus;
    public doctorProfile: DoctorProfile;

    public constructor(init?: Partial<UpdateDoctorProfileResponse>) { (Object as any).assign(this, init); }
}

export class ActiveSubstancesResponse
{
    public activeSubstances: ActiveSubstance[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ActiveSubstancesResponse>) { (Object as any).assign(this, init); }
}

export class AtcGroupsResponse
{
    public atcGroups: AtcGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AtcGroupsResponse>) { (Object as any).assign(this, init); }
}

export class CountriesResponse
{
    public countries: Country[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CountriesResponse>) { (Object as any).assign(this, init); }
}

export class DosageForm
{
    // @StringLength(12)
    public code: string;

    // @StringLength(12)
    public title: string;

    // @StringLength(12)
    public description: string;

    public constructor(init?: Partial<DosageForm>) { (Object as any).assign(this, init); }
}

// @Route("/eislp/drugs/{Code}", "GET")
export class DrugDetail implements IReturn<DrugDetail>
{
    /**
     * Drug code. No default value.
     */
    public code: string;

    public constructor(init?: Partial<DrugDetail>) { (Object as any).assign(this, init); }
    public createResponse() { return new DrugDetail(); }
    public getTypeName() { return 'DrugDetail'; }
}

export class DrugCountResponse
{
    public count: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DrugCountResponse>) { (Object as any).assign(this, init); }
}

export class DrugListResponse
{
    public drugs: DrugResult[];
    public responseStatus: ResponseStatus;
    public page: number;
    public itemsPerPage: number;
    public itemsOnPage: number;
    public itemsTotal: number;

    public constructor(init?: Partial<DrugListResponse>) { (Object as any).assign(this, init); }
}

export class DrugsSuggestionsResponse
{
    public drugsSuggestions: DrugSuggestion[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DrugsSuggestionsResponse>) { (Object as any).assign(this, init); }
}

export class IndicationGroupsResponse
{
    public indicationGroups: IndicationGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<IndicationGroupsResponse>) { (Object as any).assign(this, init); }
}

export class IndicationRestriction
{
    public id: number;
    // @StringLength(10)
    public atcGroup: string;

    // @StringLength(150)
    public title: string;

    public supplement: string;
    public restriction: string;

    public constructor(init?: Partial<IndicationRestriction>) { (Object as any).assign(this, init); }
}

export class PrescriptionRestrictionsResponse
{
    public prescriptionRestrictions: PrescriptionRestriction[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PrescriptionRestrictionsResponse>) { (Object as any).assign(this, init); }
}

export class LabSource
{
    public id: number;
    public name: string;
    public protocolId: number;
    // @Ignore()
    public protocol: FtpProtocol;

    public server: string;
    public port: number;
    public remotePath: string;
    public passive: boolean;
    public containerId: number;
    // @Ignore()
    public container: ExamContainer;

    public extension: string;
    public deleteAfterId: number;
    // @Ignore()
    public deleteAfter: ExamDeleteWhen;

    public internalNote: string;
    public validThrough: string;
    public replacedWithId: number;

    public constructor(init?: Partial<LabSource>) { (Object as any).assign(this, init); }
}

export class GetDoctorMessagingProfilesResponse
{
    public doctorMessagingProfiles: DoctorMessagingProfile[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetDoctorMessagingProfilesResponse>) { (Object as any).assign(this, init); }
}

export class GetDoctorMessagingProfileResponse
{
    public doctorMessagingProfile: DoctorMessagingProfile;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetDoctorMessagingProfileResponse>) { (Object as any).assign(this, init); }
}

export class CreatePatientTempCredentialsResponse
{
    public patientTempCredentials: PatientTempCredentials;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreatePatientTempCredentialsResponse>) { (Object as any).assign(this, init); }
}

export class CheckPatientTempProfileExistsResponse
{
    public tmpPatientProfileId: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CheckPatientTempProfileExistsResponse>) { (Object as any).assign(this, init); }
}

export class CreatePatientMessageResponse
{
    public patientMessage: PatientMessage;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreatePatientMessageResponse>) { (Object as any).assign(this, init); }
}

export class DeletePatientMessageResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeletePatientMessageResponse>) { (Object as any).assign(this, init); }
}

export class PatientMessagesResponse
{
    public patientMessages: PatientMessage[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientMessagesResponse>) { (Object as any).assign(this, init); }
}

export class PatientMessageResponse
{
    public patientMessage: PatientMessage;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientMessageResponse>) { (Object as any).assign(this, init); }
}

export class WaitingRoomsResponse
{
    public waitingRooms: WaitingRoom[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<WaitingRoomsResponse>) { (Object as any).assign(this, init); }
}

export class AppointmentsResponse
{
    public appointments: Appointment[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AppointmentsResponse>) { (Object as any).assign(this, init); }
}

export class AppointmentResponse
{
    public appointment: Appointment;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AppointmentResponse>) { (Object as any).assign(this, init); }
}

export class GetSystemEnvironmentResponse
{
    public environment: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSystemEnvironmentResponse>) { (Object as any).assign(this, init); }
}

export class CommentsResponse
{
    public comments: Comment[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CommentsResponse>) { (Object as any).assign(this, init); }
}

export class CommentResponse
{
    public comment: Comment;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CommentResponse>) { (Object as any).assign(this, init); }
}

export class NewsResponse
{
    public news: New[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<NewsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=8)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

// @Route("/customers/doctor/profile", "GET")
export class GetDoctorProfile implements IReturn<GetDoctorProfileResponse>
{
    public kodLekara: string;
    public kodNz: string;
    public icoPzs: string;

    public constructor(init?: Partial<GetDoctorProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDoctorProfileResponse(); }
    public getTypeName() { return 'GetDoctorProfile'; }
}

// @Route("/customers/doctor/profile", "POST")
export class CreateDoctorProfile implements IReturn<CreateDoctorProfileResponse>
{
    public userName: string;
    public password: string;
    public doctorProfile: DoctorProfile;

    public constructor(init?: Partial<CreateDoctorProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDoctorProfileResponse(); }
    public getTypeName() { return 'CreateDoctorProfile'; }
}

// @Route("/customers/doctor/profile/{DoctorProfileId}", "PATCH")
export class UpdateDoctorProfile implements IReturn<UpdateDoctorProfileResponse>
{
    public doctorProfileId: string;
    public isUsingMessages: boolean;

    public constructor(init?: Partial<UpdateDoctorProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateDoctorProfileResponse(); }
    public getTypeName() { return 'UpdateDoctorProfile'; }
}

// @Route("/eislp/active-substances", "GET")
// @Route("/eislp/active-substances/drug-code/{DrugCode}", "GET")
// @Route("/eislp/active-substances/{Id}", "GET")
// @Route("/eislp/drugs/{DrugCode}/active-substances", "GET")
export class ActiveSubstances implements IReturn<ActiveSubstancesResponse>
{
    /**
     * ID of active substance. No default value.
     */
    public id: number;
    /**
     * Drug code. No default value.
     */
    public drugCode: string;

    public constructor(init?: Partial<ActiveSubstances>) { (Object as any).assign(this, init); }
    public createResponse() { return new ActiveSubstancesResponse(); }
    public getTypeName() { return 'ActiveSubstances'; }
}

// @Route("/eislp/atc-groups", "GET")
// @Route("/eislp/atc-groups/{Code}", "GET")
export class GetAtcGroups implements IReturn<AtcGroupsResponse>
{
    /**
     * ATC group code. No default value.
     */
    public code: string;

    public constructor(init?: Partial<GetAtcGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new AtcGroupsResponse(); }
    public getTypeName() { return 'GetAtcGroups'; }
}

// @Route("/eislp/countries", "GET")
// @Route("/eislp/countries/{Code}", "GET")
export class Countries implements IReturn<CountriesResponse>
{
    public code: string;

    public constructor(init?: Partial<Countries>) { (Object as any).assign(this, init); }
    public createResponse() { return new CountriesResponse(); }
    public getTypeName() { return 'Countries'; }
}

// @Route("/eislp/dosage-forms", "GET")
// @Route("/eislp/dosage-forms/{Code}", "GET")
export class DosageForms implements IReturn<DosageForm>
{
    /**
     * Code of dosage form. Use + sign instead of spaces.
     */
    public code: string;

    public constructor(init?: Partial<DosageForms>) { (Object as any).assign(this, init); }
    public createResponse() { return new DosageForm(); }
    public getTypeName() { return 'DosageForms'; }
}

// @Route("/eislp/drugs/count", "GET")
export class DrugCount implements IReturn<DrugCountResponse>
{
    public sort: string;
    public filter: string;
    public search: string;
    public position: string;

    public constructor(init?: Partial<DrugCount>) { (Object as any).assign(this, init); }
    public createResponse() { return new DrugCountResponse(); }
    public getTypeName() { return 'DrugCount'; }
}

// @Route("/eislp/drugs", "GET")
// @Route("/eislp/drugs/page/{Page}", "GET")
// @Route("/eislp/drugs/page/{Page}/items/{Items}", "GET")
export class DrugList implements IReturn<DrugListResponse>
{
    /**
     * Page to return (1 based index). No default value.
     */
    public page: number;
    /**
     * Number of items to return for each page. Default value: 100.
     */
    public items: number;
    public sort: string;
    public filter: string;
    public search: string;
    public position: string;

    public constructor(init?: Partial<DrugList>) { (Object as any).assign(this, init); }
    public createResponse() { return new DrugListResponse(); }
    public getTypeName() { return 'DrugList'; }
}

// @Route("/eislp/drugs/suggestions/", "GET")
export class DrugsSuggestions implements IReturn<DrugsSuggestionsResponse>
{
    public field: string;
    public value: string;
    public position: string;

    public constructor(init?: Partial<DrugsSuggestions>) { (Object as any).assign(this, init); }
    public createResponse() { return new DrugsSuggestionsResponse(); }
    public getTypeName() { return 'DrugsSuggestions'; }
}

// @Route("/eislp/indication-groups", "GET")
// @Route("/eislp/indication-groups/{Id}", "GET")
export class IndicationGroups implements IReturn<IndicationGroupsResponse>
{
    /**
     * ID of indication group. No default value.
     */
    public id: number;

    public constructor(init?: Partial<IndicationGroups>) { (Object as any).assign(this, init); }
    public createResponse() { return new IndicationGroupsResponse(); }
    public getTypeName() { return 'IndicationGroups'; }
}

// @Route("/eislp/indication-restrictions", "GET")
// @Route("/eislp/indication-restrictions/{Id}", "GET")
// @Route("/eislp/indication-restrictions/atc-group/{AtcGroupCode}", "GET")
// @Route("/eislp/indication-restrictions/drug/{DrugCode}", "GET")
export class IndicationRestrictions implements IReturn<IndicationRestriction>
{
    /**
     * ID of indication restriction. No default value.
     */
    public id: number;
    /**
     * ATC group code. No default value.
     */
    public atcGroupCode: string;
    /**
     * Drug code. No default value.
     */
    public drugCode: string;

    public constructor(init?: Partial<IndicationRestrictions>) { (Object as any).assign(this, init); }
    public createResponse() { return new IndicationRestriction(); }
    public getTypeName() { return 'IndicationRestrictions'; }
}

// @Route("/eislp/prescription-restrictions", "GET")
// @Route("/eislp/prescription-restrictions/drug-code/{DrugCode}", "GET")
// @Route("/eislp/prescription-restrictions/{Code}", "GET")
// @Route("/eislp/drugs/{DrugCode}/prescription-restrictions", "GET")
export class PrescriptionRestrictions implements IReturn<PrescriptionRestrictionsResponse>
{
    /**
     * Code of prescription restriction. No default value.
     */
    public code: string;
    /**
     * Drug code. No default value.
     */
    public drugCode: string;

    public constructor(init?: Partial<PrescriptionRestrictions>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrescriptionRestrictionsResponse(); }
    public getTypeName() { return 'PrescriptionRestrictions'; }
}

// @Route("/labsources", "GET")
export class GetLabSources implements IReturn<LabSource[]>
{

    public constructor(init?: Partial<GetLabSources>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<LabSource>(); }
    public getTypeName() { return 'GetLabSources'; }
}

// @Route("/labsources/{Id}", "GET")
export class GetLabSource implements IReturn<LabSource>
{
    public id: number;

    public constructor(init?: Partial<GetLabSource>) { (Object as any).assign(this, init); }
    public createResponse() { return new LabSource(); }
    public getTypeName() { return 'GetLabSource'; }
}

// @Route("/svlzlabs", "GET")
export class GetSvlzLabs implements IReturn<SvlzLab[]>
{

    public constructor(init?: Partial<GetSvlzLabs>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<SvlzLab>(); }
    public getTypeName() { return 'GetSvlzLabs'; }
}

// @Route("/communication/doctor/profiles", "GET")
export class GetDoctorMessagingProfiles implements IReturn<GetDoctorMessagingProfilesResponse>
{
    public query: string;

    public constructor(init?: Partial<GetDoctorMessagingProfiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDoctorMessagingProfilesResponse(); }
    public getTypeName() { return 'GetDoctorMessagingProfiles'; }
}

// @Route("/communication/doctor/profile/{DoctorMessagingProfileId}", "GET")
export class GetDoctorMessagingProfile implements IReturn<GetDoctorMessagingProfileResponse>
{
    public doctorMessagingProfileId: string;

    public constructor(init?: Partial<GetDoctorMessagingProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDoctorMessagingProfileResponse(); }
    public getTypeName() { return 'GetDoctorMessagingProfile'; }
}

// @Route("/customers/patient/credentials", "POST")
export class CreatePatientTempCredentials implements IReturn<CreatePatientTempCredentialsResponse>
{
    public patientTempCredentials: PatientTempCredentials;

    public constructor(init?: Partial<CreatePatientTempCredentials>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePatientTempCredentialsResponse(); }
    public getTypeName() { return 'CreatePatientTempCredentials'; }
}

// @Route("/customers/patient/{TmpPatientProfileId}", "GET")
export class CheckPatientTempProfileExists implements IReturn<CheckPatientTempProfileExistsResponse>
{
    public tmpPatientProfileId: string;

    public constructor(init?: Partial<CheckPatientTempProfileExists>) { (Object as any).assign(this, init); }
    public createResponse() { return new CheckPatientTempProfileExistsResponse(); }
    public getTypeName() { return 'CheckPatientTempProfileExists'; }
}

// @Route("/communication/messages", "POST")
export class CreatePatientMessage implements IReturn<CreatePatientMessageResponse>
{
    // @ApiMember(IsRequired=true)
    public patientMessage: PatientMessage;

    public constructor(init?: Partial<CreatePatientMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePatientMessageResponse(); }
    public getTypeName() { return 'CreatePatientMessage'; }
}

// @Route("/communication/message/{MessageId}", "DELETE")
export class DeletePatientMessage implements IReturn<DeletePatientMessageResponse>
{
    // @ApiMember(IsRequired=true)
    public messageId: number;

    public constructor(init?: Partial<DeletePatientMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeletePatientMessageResponse(); }
    public getTypeName() { return 'DeletePatientMessage'; }
}

// @Route("/communication/messages/to/{DoctorProfileId}", "GET")
export class GetPatientSentMessages implements IReturn<PatientMessagesResponse>
{
    // @ApiMember()
    public doctorProfileId: string;

    public constructor(init?: Partial<GetPatientSentMessages>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientMessagesResponse(); }
    public getTypeName() { return 'GetPatientSentMessages'; }
}

// @Route("/communication/messages", "GET")
// @Route("/communication/messages/{MessageIds}", "GET")
// @Route("/communication/messages/from/{PatientTmpProfileId}", "GET")
export class GetDoctorReceivedMessages implements IReturn<PatientMessagesResponse>
{
    // @ApiMember()
    public includeIgnored: boolean;

    // @ApiMember()
    public messageIds: number[];

    // @ApiMember()
    public patientTmpProfileId: string;

    public constructor(init?: Partial<GetDoctorReceivedMessages>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientMessagesResponse(); }
    public getTypeName() { return 'GetDoctorReceivedMessages'; }
}

// @Route("/communication/message/{MessageId}", "GET")
export class GetDoctorReceivedMessage implements IReturn<PatientMessageResponse>
{
    // @ApiMember()
    public messageId: number;

    public constructor(init?: Partial<GetDoctorReceivedMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientMessageResponse(); }
    public getTypeName() { return 'GetDoctorReceivedMessage'; }
}

// @Route("/communication/message/{MessageId}", "PATCH")
export class UpdatePatientMessage implements IReturn<PatientMessageResponse>
{
    // @ApiMember(IsRequired=true)
    public isMessageRead: boolean;

    // @ApiMember(IsRequired=true)
    public isIgnored: boolean;

    // @ApiMember(IsRequired=true)
    public reply: string;

    // @ApiMember(IsRequired=true)
    public messageId: number;

    public constructor(init?: Partial<UpdatePatientMessage>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientMessageResponse(); }
    public getTypeName() { return 'UpdatePatientMessage'; }
}

// @Route("/reception/waitingrooms", "GET")
// @Route("/reception/waitingrooms/{WaitingRoomId}", "GET")
export class WaitingRooms implements IReturn<WaitingRoomsResponse>
{
    public waitingRoomId: number;

    public constructor(init?: Partial<WaitingRooms>) { (Object as any).assign(this, init); }
    public createResponse() { return new WaitingRoomsResponse(); }
    public getTypeName() { return 'WaitingRooms'; }
}

// @Route("/reception/appointments", "GET")
// @Route("/reception/appointments/{AppointmentId}", "GET")
// @Route("/reception/appointments/waitingroom/{WaitingRoomId}", "GET")
export class GetAppointments implements IReturn<AppointmentsResponse>
{
    public appointmentId: number;
    public waitingRoomId: number;

    public constructor(init?: Partial<GetAppointments>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppointmentsResponse(); }
    public getTypeName() { return 'GetAppointments'; }
}

// @Route("/reception/appointments/{AppointmentId}", "PATCH")
export class UpdateAppointment implements IReturn<AppointmentResponse>
{
    public appointmentId: number;
    public bookingTime: string;

    public constructor(init?: Partial<UpdateAppointment>) { (Object as any).assign(this, init); }
    public createResponse() { return new AppointmentResponse(); }
    public getTypeName() { return 'UpdateAppointment'; }
}

// @Route("/system/environment", "GET")
export class GetSystemEnvironment implements IReturn<GetSystemEnvironmentResponse>
{

    public constructor(init?: Partial<GetSystemEnvironment>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSystemEnvironmentResponse(); }
    public getTypeName() { return 'GetSystemEnvironment'; }
}

// @Route("/web/comments", "GET")
// @Route("/web/comments/{Id}", "GET")
export class GetComments implements IReturn<CommentsResponse>
{
    public id: number;

    public constructor(init?: Partial<GetComments>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommentsResponse(); }
    public getTypeName() { return 'GetComments'; }
}

// @Route("/web/comments/{Id}/replies", "GET")
export class GetCommentReplies implements IReturn<CommentsResponse>
{
    public id: number;

    public constructor(init?: Partial<GetCommentReplies>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommentsResponse(); }
    public getTypeName() { return 'GetCommentReplies'; }
}

// @Route("/web/comments", "POST")
// @Route("/web/comments/{ReplyTo}/replies", "POST")
export class CreateComment implements IReturn<CommentResponse>
{
    public replyTo: number;
    public author: string;
    public text: string;

    public constructor(init?: Partial<CreateComment>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommentResponse(); }
    public getTypeName() { return 'CreateComment'; }
}

// @Route("/web/news", "GET")
// @Route("/web/news/{Id}", "GET")
export class News implements IReturn<NewsResponse>
{
    /**
     * New message ID. No default value.
     */
    public id: number;

    public constructor(init?: Partial<News>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewsResponse(); }
    public getTypeName() { return 'News'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/register")
// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin: boolean;

    // @DataMember(Order=9)
    public continue: string;

    // @DataMember(Order=10)
    public errorView: string;

    // @DataMember(Order=11)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Register>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegisterResponse(); }
    public getTypeName() { return 'Register'; }
}

