import { combineReducers } from 'redux';
import { History } from 'history';
import {connectRouter, RouterState} from "connected-react-router";

import {CounterState, counterReducer} from '../features/counter/counterSlice';
import {userReducer, UserState} from "../features/userSlice";

export interface RootState {
    router: RouterState;
    counter: CounterState,
    user: UserState,
}

export const rootReducer = (history: History) =>
    combineReducers<RootState>({
        router: connectRouter(history),
        counter: counterReducer,
        user: userReducer,
});

export default rootReducer;