import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {persistor, store, history} from './app/store';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter} from "connected-react-router";
import {PersistGate} from 'redux-persist/integration/react';
import Loading from "./features/loading";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, CssBaseline} from "@material-ui/core";

const lightTheme = createMuiTheme({
    palette: {
        type: "light",
        primary: {
            main: '#00356a',
        }
    }
});

/*const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: '#00356a',
        }
    },
});*/


ReactDOM.render(<Loading/>, document.getElementById('root'));

ReactDOM.render(
    <PersistGate loading={<Loading/>} persistor={persistor}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <GoogleReCaptchaProvider data-theme="dark"
                    reCaptchaKey="6LfvmeoUAAAAAAq5HAYGmiYCPgk8ow-JsJusAzvj"
                    language="sk">
                    <ThemeProvider theme={lightTheme}>
                        <CssBaseline>
                            <App/>
                        </CssBaseline>
                    </ThemeProvider>
                </GoogleReCaptchaProvider>
            </ConnectedRouter>
        </Provider>
    </PersistGate>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
