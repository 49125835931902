import React from "react";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import {CircularProgress, Container, Grid, Paper, TextField, Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import useDebounce from "../../features/useDebounce";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {DoctorMessagingProfile} from "../../app/dtos";
import {GetDoctorProfiles} from "../../app/apiclient";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        backButton: {
            paddingTop: theme.spacing(1),
        },
        backButtonIcon: {
            display: 'inline',
            float: 'left',
            marginRight: theme.spacing(1)
        }
    }),
);

const getDoctorDisplayName = (profile: DoctorMessagingProfile): string => {
    return `${profile.titleBefore ? `${profile.titleBefore} ` : ""}`
        + `${profile.firstName ? `${profile.firstName} ` : ""}`
        + `${profile.middleName ? `${profile.middleName} ` : ""}`
        + `${profile.lastName ? `${profile.lastName}` : ""}`
        + ` ${profile.titleAfter ? ` ${profile.titleAfter}` : ""}`;
}

const getDoctorDisplayTitle = (profile: DoctorMessagingProfile): string => {
    return `${profile.kodLekara ? `${profile.kodLekara} - ` : ""}`
        + getDoctorDisplayName(profile)
        + `${profile.nazovPzs ? `, ${profile.nazovPzs}` : ""}`
        + `${profile.specialization ? ` (${profile.specialization})` : ""}`;
}

function SearchDoctorComponent() {
    const classes = useStyles();
    const history = useHistory();

    const [doctorAutocompleteOpen, setDoctorAutocompleteOpen] = React.useState(false);
    const [doctorAutocomplete, setDoctorAutocomplete] = React.useState<DoctorMessagingProfile[]>([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [loadingAutocomplete, setLoadingAutocomplete] = React.useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const searchInputValueChanged = (event: object, value: string | null) => {

        if (!value || value==="") {
            return;
        }

        setSearchTerm(value);

    }

    const doctorAutocompleteSelectedValueChanged = (event: object, value: DoctorMessagingProfile | null) => {
        if (!value || !value.id) {
            return;
        }
        history.push(`/lekar/${value.id}/spravy`)
    };

    React.useEffect(() => {

        if (!debouncedSearchTerm || debouncedSearchTerm.length < 2) {
            setDoctorAutocompleteOpen(false);
            return undefined;
        }

        let active = true;

        (async () => {
            try {
                setLoadingAutocomplete(true);
                const doctorAutocomplete = await GetDoctorProfiles(debouncedSearchTerm);
                if (active) {
                    setDoctorAutocomplete(doctorAutocomplete);
                    setLoadingAutocomplete(false);
                    setDoctorAutocompleteOpen(true);
                }
            } catch (e) {
                console.log(e);
                setDoctorAutocomplete([]);
                setLoadingAutocomplete(false);
                setDoctorAutocompleteOpen(true);
            }
        })();

        return () => {
            active = false;
            setLoadingAutocomplete(false);
            setDoctorAutocompleteOpen(true);
        };

    }, [debouncedSearchTerm]);

    return (
        <Container fixed>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={'h2'}>
                            Vyhľadajte lekára, ktorému chcete napísať správu
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body1"}>
                            Vyhľadávať môžete podľa priezviska, A-kódu lekára, jeho špecializácie alebo názvu
                            ambulancie.
                        </Typography>
                        <Typography variant={"body1"}>
                            Je potrebné zadať minimálne 2 znaky, vyhľadávanie funguje od začiatku slova.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            autoSelect={false}
                            autoHighlight={false}
                            clearOnEscape={false}
                            clearOnBlur={false}
                            id="doctorProfile-select"
                            open={doctorAutocompleteOpen}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => {
                                return getDoctorDisplayTitle(option);
                            }}
                            options={doctorAutocomplete}
                            noOptionsText={"Hľadanému výrazu nezodpovedajú žiadni lekári."}
                            loadingText={"Načítavam zoznam lekárov..."}
                            loading={loadingAutocomplete}
                            onInputChange={searchInputValueChanged}
                            onChange={doctorAutocompleteSelectedValueChanged}
                            onBlur={() => setDoctorAutocompleteOpen(false)}
                            onFocus={() => searchTerm.length > 1 && setDoctorAutocompleteOpen(true)}
                            renderOption={(option, {inputValue}) => {
                                const label = getDoctorDisplayTitle(option);
                                const matches = match(label, inputValue);
                                const parts = parse(label, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                                {part.text}
                                              </span>
                                        ))}
                                    </div>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    focused={true}
                                    autoFocus={true}
                                    label="Napíšte aspoň 2 znaky..."
                                    variant="outlined"
                                    autoComplete={"off"}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingAutocomplete ?
                                                    <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default SearchDoctorComponent;